import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import iceSlider from './libs/ice-slider.js';
import animating from './libs/animating';
import parallaxScroll from './libs/parallax.js';


import { gsap, TweenLite, TweenMax } from "gsap";
gsap.registerPlugin(TweenLite, TweenMax);


window.$ = $

const home = {};

home.loader = function () {
  let wrap = $(".loader"),
    wrap_bg = wrap.find('.innerLoad'),
    isiloader = wrap.find('.bg-load'),
    lines = wrap.find('.line-load span'),
    iload = 0,
    width = 0,
    load = $("body img").length;
  // vlo = 0;
  let anim = function () {
    TweenMax.to(wrap, 0.4, {
      opacity: 1, display: 'block', delay: 0, onComplete: function () {
        TweenMax.to(lines, 5, {
          width: '100%',
          delay: 0,
          // ease: SlowMo.ease.config (0.1, 0.3, false),
          onComplete: function () {
            TweenMax.to(isiloader, .4, {
              top: '100%',
              ease: "Power2.easeOut",
              delay: 0
            });
            TweenMax.to(wrap_bg, .4, {
              opacity: 0,
              ease: "Power2.easeOut",
              delay: 0
            });
            setTimeout(function () {
              // wrap.css('display', 'none');
              wrap.fadeOut('normal');
            }, 1000);
          }
        });
      }
    });
  }
  anim();
  if ($(".loader").length) {
    let delay = 0;
    $("body img").bind('load', function () {
      iload++;
      delay = iload;
      width = (iload / load) * 100;
      if (iload == load) {
        anim();
      }
    });
    anim();
  }
}

home.homeSlider = function () {
  const slider = $('.bannerhome .slider'),
    figcaption = slider.find("figcaption");
  slider.slick({
    arrows: true,
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 900,
    pauseOnFocus: false,
    initialSlide: length - 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          adaptiveHeight: true
        }
      }
    ]
  });

  TweenLite.to(slider.find(".slick-list .slick-slide figure > img, .slick-list .slick-slide figure span > img"), 15, { css: { scale: 1.15 }, ease: 'Power2.easeOut' });

  if (slider.length) {
    let bg = slider.find(".slick-list .slick-slide figure > img, .slick-list .slick-slide figure span > img");

    TweenLite.set(bg, { css: { 'position': 'relative', scale: 1 } });
    TweenLite.set(figcaption.find('.txtdate'), { css: { 'position': 'relative' } });
    TweenLite.set(figcaption.find('h2'), { css: { 'position': 'relative' } });
    TweenLite.set(figcaption.find('p'), { css: { 'position': 'relative' } });
    TweenLite.set(figcaption.find('.wrap-button'), { css: { 'position': 'relative' } });
  }
  slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    let caption = slider.find(".slick-slide[data-slick-index=" + nextSlide + "]"),
      img = slider.find(".slick-list .slick-slide figure > img, .slick-list .slick-slide figure span > img");

    TweenLite.to(img, 1.05, { css: { scale: 1 }, ease: 'Power2.easeOut' });

    //not use
    /*TweenLite.to(caption.find('.txtdate'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Power2.easeOut'});
    TweenLite.to(caption.find('h2'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Power2.easeOut'});
    TweenLite.to(caption.find('p'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Power2.easeOut'});
    TweenLite.to(caption.find('.wrap-button'), 0.2, {css: {'left': '-50px','opacity': '0',},ease: 'Power2.easeOut'});*/

    //set default
    TweenLite.set(caption.find('.txtdate'), { css: { 'left': '-50px', 'opacity': '0', }, ease: 'Power2.easeOut' });
    TweenLite.set(caption.find('h2'), { css: { 'left': '-50px', 'opacity': '0', }, ease: 'Power2.easeOut' });
    TweenLite.set(caption.find('p'), { css: { 'left': '-50px', 'opacity': '0', }, ease: 'Power2.easeOut' });
    TweenLite.set(caption.find('.wrap-button'), { css: { 'left': '-50px', 'opacity': '0', }, ease: 'Power2.easeOut' });

    //to anim
    TweenLite.to(caption.find('.txtdate'), 0.3, { css: { 'left': '0', 'opacity': '1', }, delay: 0.3, ease: 'Power2.easeOut' });
    TweenLite.to(caption.find('h2'), 0.3, { css: { 'left': '0', 'opacity': '1', }, delay: 0.4, ease: 'Power2.easeOut' });
    TweenLite.to(caption.find('p'), 0.3, { css: { 'left': '0', 'opacity': '1', }, delay: 0.5, ease: 'Power2.easeOut' });
    TweenLite.to(caption.find('.wrap-button'), 0.3, { css: { 'left': '0', 'opacity': '1', }, delay: 0.6, ease: 'Power2.easeOut' });

  });
  slider.on('afterChange', function (event, slick, currentSlide) {
    let caption = slider.find(".slick-slide[data-slick-index=" + currentSlide + "]"),
      // img = slider.find(".slick-list .slick-slide figure > img");
      img = slider.find(".slick-slide[data-slick-index=" + currentSlide + "] figure img");

    TweenLite.to(img, 15, { css: { scale: 1.15 }, ease: 'Power2.easeOut' });

  });
}
home.storyLogo = function () {
  const slider = $('.logo-list .slider');
  slider.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5
        }
      }
    ]
  });
}

home.productHome = function () {
  const slider = $('.our-product .product-list');
  slider.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
}
home.connectSlider = function () {
  const slider = $('.box-connect .slider');
  slider.slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    arrows: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });
}
home.animateScroll = function () {
  let animating_top = $(".animating-top"), //element
    animating_bottom = $(".animating-bottom"), //element
    animating_left = $(".animating-left"), //element
    animating_right = $(".animating-right"); //element

  animating($, gsap).from({
    element: animating_top,
    from: 'from-top', //animation type 'from-top','from-bottom','from-left','from-right','fade','swipe-horizontal','swipe-vertical'
    time: .5, // time in secnd
    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
  })
  animating($, gsap).from({
    element: animating_bottom,
    from: 'from-bottom', //animation type
    time: .5, // time in secnd
    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
  })
  animating($, gsap).from({
    element: animating_right,
    from: 'from-right', //animation type
    time: .5, // time in secnd
    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
  })
  animating($, gsap).from({
    element: animating_left,
    from: 'from-left', //animation type
    time: .5, // time in secnd
    ease: 'power3.easeOut' // easeing https://greensock.com/docs/v3/Eases
  })
}
home.videoPlay = function () {
  $('.wrap-video .thumb-video .play').on('click', function () {
    $(this).parents('.wrap-video').find('.wrap-yt').show();
    $(this).parents('.wrap-video').find('.wrap-yt').each(function () {
      var iframe = $(this).find('iframe');
      setTimeout(function () {
        iframe[0].src = iframe.attr('src').replace('?&autoplay=0', '?&autoplay=1');
      }, 100);
    });
  });
}

home.init = function () {
  this.loader()
  this.homeSlider()
  iceSlider($).init()
  this.storyLogo()
  this.productHome()
  this.connectSlider()
  this.animateScroll()
  this.videoPlay()
}


$(function () {
  home.init()
  //init parallax
  parallaxScroll($).parallax('.parallax-scroll')
})








