import { gsap, TweenLite, TweenMax} from "gsap/all";
gsap.registerPlugin(TweenLite, TweenMax); 

export default($) => ({
	parallax(t){
		let elem = $(t);
    const initFunc = function () {
        let topoffset;
        elem.each(function () {
            topoffset = ($(this).offset().top - $(window).height()) / $(this).attr('offset-amount') - 0.5;
            // console.log($(this).css('position'))
            topoffset < 0 ? topoffset = 0 : '';
            $(this).attr('offset-top', $(this).offset().top);
            topoffset = (parseFloat($(this).attr('offset-top')) - $(window).scrollTop()) / parseFloat($(this).attr('offset-amount'));

            if ($(this).css('position') === 'static')
                $(this).css({
                    position: 'relative',
                });

            TweenLite.to($(this), 0.8, {top: topoffset, delay: 0.2, ease: "Power3.easeOut"});

        });
    };    
    const animFunc = function (top) {
        let topoffset;
        elem.each(function () {
            topoffset = (parseFloat($(this).attr('offset-top')) - top) / parseFloat($(this).attr('offset-amount'));
            TweenLite.to($(this), 0.9, {top: topoffset, overwrite: 5, ease: "Power3.easeOut"});           
        });
    };   
    
    $(window).on('load', function () {
        initFunc();
    });
    $(window).on('scroll', function () {        
        const scroll_top = $(this).scrollTop();
            animFunc(scroll_top);            
    });
	}
});