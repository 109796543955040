
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

export default ($) => ({
  init() {
    $('.ice-list .slider').slick({
      fade: true,
      arrows: false,
      dots: false,
      infinite: false,
      autoplay: true,
      asNavFor: '.ice-list .caption'
    });
    $('.ice-list .caption').slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      autoplay: true,
      asNavFor: '.ice-list .slider',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    });
  },
})
